import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import {
  PorscheDesignSystemProvider,
  PToast,
} from '@porsche-design-system/components-react';
import { PreloadImages } from './components/misc/PreloadImages';
import './index.scss';
import { Router } from './router';
import { configure } from 'mobx';
import { BackendClient } from './backend-client';

if (process.env.REACT_APP_IS_NEW_RELIC_ENABLED) {
  new BrowserAgent({
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: [process.env.REACT_APP_NEW_RELIC_BEACON] },
    },
    info: {
      beacon: process.env.REACT_APP_NEW_RELIC_BEACON,
      errorBeacon: process.env.REACT_APP_NEW_RELIC_BEACON,
      licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
      applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
      sa: 1,
    },
    loader_config: {
      accountID: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
      trustKey: process.env.REACT_APP_NEW_RELIC_TRUST_KEY,
      agentID: process.env.REACT_APP_NEW_RELIC_AGENT_ID,
      licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
      applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
    },
  });
}

BackendClient.OpenAPI.BASE = process.env.REACT_APP_BASE_API_URL || '';
configure({ enforceActions: 'never' });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <PreloadImages />
    <PorscheDesignSystemProvider prefix="my-charging">
      <Router />
      <PToast />
    </PorscheDesignSystemProvider>
  </React.StrictMode>,
);
