import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { Authentication } from '../../../hooks/useAuthentication/useAuthentication';
import { BackendClient } from '../../../backend-client';

type AvailableFeatureFlags = {
  cookiesConsent?: { enabled: boolean };
  cookiesConsentVersion?: { enabled: boolean; currentVersion?: number };
};

export class FeatureFlagsContext {
  public featureFlags: AvailableFeatureFlags;

  private auth: Authentication;

  constructor(props: { auth: Authentication; dummy: boolean }) {
    makeAutoObservable(this);
    this.auth = props.auth;

    if (props.dummy) {
      return;
    }

    this.fetchData();
  }

  private fetchData = async () => {
    try {
      const featureFlagsResponse =
        await BackendClient.featureFlagsControllerGetFeatureFlags();
      this.featureFlags = featureFlagsResponse as AvailableFeatureFlags;
    } catch (e: unknown) {
      console.error(e);
      this.featureFlags = {};
    }
  };
}

export const featureFlagsDummyContext = new FeatureFlagsContext({
  auth: {} as Authentication,
  dummy: true,
});

export const featureFlagsContext = createContext<FeatureFlagsContext>(
  featureFlagsDummyContext,
);
